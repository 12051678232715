<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    :class="
      $route.meta.contentRenderer === 'spaceless'
        ? 'content-header-spaceless'
        : 'content-header'
    "
  >
  <b-row class="top-breadcrumb" v-if="$route.meta.active === true">
    <b-col cols="12">
      <div class="breadcrumb-wrapper">
        <div
          class="top-breadcrumb_title float-left  mb-0"
          :class="!$route.meta.breadcrumb ? 'hidden-border' : ''"
        >
          <router-link
            class="pr-1"
            :class="$route.meta.active === true ? 'link' : ''"
            :is="$route.meta.active === true ? 'router-link' : 'span'"
            :to="$route.meta.active === true && { name: $route.meta.to }"
          >
            {{ $route.meta.pageTitle }} 
          </router-link>
          <feather-icon
          icon="ChevronRightIcon"
          size="13"
        /> 
        </div>
       
        <b-breadcrumb>
          <b-breadcrumb-item
            v-for="item in $route.meta.breadcrumb"
            :key="item.text"
            :active="item.active"
            :to="item.to"
          >
          
          {{ item.text }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </b-col>
  </b-row>
    <!-- Content Left -->
    <b-col
      :class="
        $route.meta.contentRenderer === 'spaceless'
          ? 'content-header-left-spaceless d-flex align-items-center'
          : 'content-header-left mb-2'
      "
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2
            class="content-header-title float-left pr-1 mb-0"
            :class="!$route.meta.breadcrumb ? 'hidden-border' : ''"
          >
            <span>
              {{ $route.meta.pageTitle }}
            </span>
          </h2>
          <div class="breadcrumb-wrapper">
              <h2 class="mb-0"
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                <span class=" pl-1">{{ item.text }}</span>
              </h2>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
};
</script>

<style lang="scss" scoped>
.content-header-spaceless {
  padding-left: 32px;
  background-color: white;
  height: 83px;
  border-bottom: 1px solid #e0e0e0;
}

.link {
  text-decoration: none;
  color: #377dff;

  &:hover {
    color: #699dff;
  }
}

.hidden-border {
  border-right: none !important;
}
</style>
