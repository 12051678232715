export default [
  {
    title: "ダッシュボード",
    icon: "GridIcon",
    route: "dashboard",
    role_limit: ["system_admin", "staff_admin"],
    show: true,
  },
  {
    title: "ルーム",
    icon: "AirplayIcon",
    route: "room-list",
    role_limit: ["system_admin", "staff_admin"],
    show: true,
  },
  {
    title: "ユーザー",
    icon: "UsersIcon",
    route: "user-list",
    role_limit: ["system_admin", "staff_admin"],
    show: true,
  },
  {
    title: "お問い合わせ",
    tag: "inquiry",
    tagVariant: "danger",
    icon: "MessageSquareIcon",
    route: "inquiry-list",
    role_limit: ["system_admin", "staff_admin"],
    show: true,
  },
  {
    title: "お知らせ",
    icon: "BellIcon",
    route: "notification-list",
    role_limit: ["system_admin", "staff_admin"],
    show: true,
  },
  {
    title: "売上",
    icon: "BarChart2Icon",
    route: "earnings-list",
    role_limit: ["system_admin"],
    show: true,
  },
  {
    title: "契約",
    icon: "BookIcon",
    route: "contract-list",
    role_limit: ["system_admin"],
    show: true,
  },
  {
    title: "スライド広告",
    icon: "SidebarIcon",
    route: "slide-list",
    role_limit: ["system_admin", "staff_admin"],
    show: true,
  },
  {
    title: "アカウント管理",
    icon: "AirplayIcon",
    route: "account-list",
    role_limit: ["system_admin"],
    show: true,
  },
  {
    title: "禁止情報設定",
    icon: "SlashIcon",
    route: "prohibited-information-list",
    role_limit: ["system_admin", "staff_admin"],
    show: true,
  },
  //* menu footer
  {
    title: "設定",
    icon: "SettingsIcon",
    route: "setting",
  },
  {
    title: "ログアウト",
    icon: "LogOutIcon",
    route: "logout",
  },
];
