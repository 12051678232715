<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
      v-if="
        item.route !== 'logout' &&
        item.route !== 'setting' &&
        checkRole(item.role_limit)
      "
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag && totalNumberMsg > 0"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ totalNumberMsg }}
      </b-badge>
    </b-link>
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
      :class="user_role.includes('system_admin') ? 'margin_low' : 'margin_high'"
      v-if="item.route === 'setting'"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
    </b-link>
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
      v-if="item.route === 'logout'"
      @click="logout"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl";
import { BLink, BBadge } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";
import JwtService from "@/services/jwt.service";
import store from "@/store";
import {computed} from "@vue/composition-api";

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user_role: [],
    };
  },
  mounted() {
    if (this.$store.state.app.admin) {
      this.user_role = this.$store.state.app.admin.role_type;
    }
  },
  methods: {
    /* <!--@--> (logout): Handle logout ------------------------------------------------------------------------- */
    logout() {
      JwtService.destroyToken();
      localStorage.removeItem("user");
      this.$router.push({ name: "login" });
      this.$store.dispatch("app/setAuthStatus", "logout");
      // stop listerner firebase
      this.$store.dispatch("totalCountUnreadMsg/unsubscribe")
    },

    /* <!--@--> (checkRole): Check permission of roles that can access sidebar ------------------------------------------------------------------------- */
    checkRole(role_limits) {
      let check = false;
      this.user_role.forEach((item) => {
        role_limits.forEach((role_limit) => {
          if (role_limit == item) {
            check = true;
          }
        });
      });
      return check
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    );
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();
    const totalNumberMsg = computed(()=>{
      return store.getters["totalCountUnreadMsg/getTotalNumberOfMsg"];
    });
    return {
      totalNumberMsg,
      isActive,
      linkProps,
      updateIsActive,
      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },
};
</script>

<style scoped>
.margin_low {
  margin-top: calc(100vh - 63px - 580px) !important;
}

.margin_high {
  margin-top: calc(100vh - 63px - 410px) !important;
}
</style>
